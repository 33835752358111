import React, {PureComponent} from 'react'
//import {Text, FlexContainer} from "../../index.style";
import {
    FlexContainer,
    BackgroundStyle,
    Text,
    Block,
    Button,

} from './index.style'

export default class Consultation extends PureComponent {
    render() {
        return (
            <FlexContainer className="center stretch">
                <BackgroundStyle>
                    <FlexContainer className="center">
                        <FlexContainer className="block stretchBlock directionRow">
                            <div>
                                asd
                            </div>
                            <div>
                                asd
                            </div>
                        </FlexContainer>
                    </FlexContainer>
                </BackgroundStyle>
            </FlexContainer>
        )
    }
}