import React, {PureComponent} from 'react'

export default class ErrorCode404 extends PureComponent {

    constructor(props) {
        super(props);
    }
    state = {

    }

    render() {
        return (
            <div>
                <h1>404</h1>
            </div>
        )
    }
}