import React, {PureComponent} from 'react'
import {
    FlexContainer,
    BackgroundStyle,
    Text,
    Block,
    Button,
} from './index.style.js'
import ListItem from "./components/listItem";
import Consultation from "../compontnts/consultation";


export default class WhyMday extends PureComponent {

    constructor(props) {
        super(props);
    }

    state = {
    }

    render() {
        return (
            <FlexContainer className="center stretch">
                <BackgroundStyle>
                    <FlexContainer className="center">
                        <FlexContainer className="block">
                            <FlexContainer className = "stretchBlock directionColumn">
                                <Block className="TitlePage">
                                    <Text className="TopWhite">Почему именно MDAY?</Text>
                                    <Text className = "TopBlue"> Нашу продукцию отличает</Text>
                                </Block>
                                <FlexContainer className="directionRow">
                                    <Block className = "MiddleFist MiddleTopBorder">
                                        <ListItem Number = "01" Title = "Эффектный дизайн" Text = "Мы делаем роботам
                                        эстетический вид и соблюдаем гармонию между техническим решением и вашим удобством"/>
                                    </Block>
                                    <Block className = "MiddleSecond MiddleTopBorder">
                                        <ListItem Number = "02" Title = "Безопасность" Text = "Нашего бота не взломать!"/>
                                    </Block>
                                    <Block className = "MiddleThird MiddleTopBorderInvisible">
                                        <ListItem Number = "03"
                                                  Title = "Производительность"
                                                  Text = {
                                                      <span>
                                                          Внушительная произаодительность <br/>
                                                          наших технологий позволит в разы <br/>
                                                          повысить вашу прибыль
                                                      </span>
                                        }/>
                                    </Block>
                                </FlexContainer>
                                <FlexContainer className="directionRow">
                                    <Block className = "MiddleFist MiddleTopBorder">
                                        <ListItem Number = "04" Title = "Обучение, сопровождение, техническая поддержка"
                                                  Text = "Наша платформа стремительно развивается. Мы не только создаем
                                                   лучшие программные обеспечения, но и обучаем, сопровождаем на пути к
                                                    высоким целям и обеспечиваем техническую поддержку нашим ботам"/>
                                    </Block>
                                    <Block className = "MiddleSecond MiddleTopBorderInvisible">
                                        <ListItem Number = "05"
                                                  Title = "Экосистема"
                                                  Text = {
                                                      <span>
                                                          Комплексный продукт, включающий <br/>
                                                          в себя ПО, стратегии, функционал, <br/>
                                                          настроек, готовые рабочие модули <br/>
                                                          настроенных стратегий (сеты), <br/>
                                                          мониторинг счёта, сервер для Forex - <br/>
                                                          всё в одном
                                                      </span>
                                                  }/>
                                    </Block>
                                    <Block className = "MiddleThird MiddleTopBorderInvisible">
                                        <Block className = "InviteContent">
                                            <Text className = "InviteBlue">Начни зарабатывать вместе с нами!</Text>
                                        </Block>
                                        <Block className = "InviteButton">
                                            <Button className = "InviteButton">
                                                <Text className = "ButtonText">
                                                    Начать зарабатывать
                                                </Text>
                                            </Button>
                                        </Block>
                                    </Block>
                                </FlexContainer>
                            </FlexContainer>
                            <Block className = "Consultation">
                                <Consultation Consultation/>
                            </Block>
                        </FlexContainer>
                    </FlexContainer>
                </BackgroundStyle>
            </FlexContainer>
        )
    }
}