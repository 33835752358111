import React, {PureComponent} from 'react'
//import mainScreenStyle from './index.style'

export default class Header extends PureComponent {

    constructor(props) {
        super(props);
    }

    state = {
    }

    render() {
        return (
            <h1>dadadadasdasdasdasdasdasdasdasd</h1>
        )
    }
}