import styled, { keyframes, css } from 'styled-components';
import { pulse } from 'react-animations';

const height = "100%"

export const FlexContainer = styled.div`
    display: flex;     
    &.center{
        justify-content: center;
        height: ${height};
    }
    &.stretch{
        align-items: stretch;
        height: ${height};
    }
    &.block{
        width: 100%;
        max-width: 1480px;
        alignment: center; 
        height: ${height};
        flex-direction: column;
    }
    &.directionRow{
        flex-direction: row;       
    }
    &.directionColumn{
        flex-direction: column;
     
    }    
    &.stretchBlock{
        justify-content: space-between;  
        width: 100%;
    }
`;

export const BackgroundStyle = styled.div`
    width: 100%;
    height: ${height};    
    background-color: #F8FBFF;
    border-radius: 10px;
`;

export const Text = styled.span`

`;

export const Block = styled.div`

`;

export const Button = styled.button`
  &.InviteButton{
    border: 0px;
    width: 289px;
    height: 66px;
    background: linear-gradient(105.59deg, #92AAFF 27.98%, #5877E4 72.43%), 
        linear-gradient(0deg, #001250, #001250), 
        linear-gradient(0deg, #001250, #001250),
        linear-gradient(0deg, #001250, #001250), 
        linear-gradient(0deg, #001250, #001250), #001250;
    border-radius: 6.52023px;  
  }
  &.InviteButton:active {
    animation: 0.5s ${keyframes`${pulse}`};
  }
`;