import styled, { keyframes, css } from 'styled-components';
//import { pulse } from 'react-animations';
import Background from './image/Background.svg'
import { pulse } from 'react-animations';

const height = "1334px";

export const FlexContainer = styled.div`
    display: flex;     
    &.center{
        justify-content: center;
        height: ${height};
    }
    &.stretch{
        align-items: stretch;
        height: ${height};
    }
    &.block{
        width: 100%;
        max-width: 1600px;
        alignment: center; 
        height: ${height};
        flex-direction: column;
    }
    &.directionRow{
        flex-direction: row;       
    }
    &.directionColumn{
        flex-direction: column;
     
    }    
    &.stretchBlock{
        justify-content: space-between;  
        width: 100%;
    }
`;
export const BackgroundStyle = styled.div`
    width: 100%;
    max-width: 1920px;
    height: ${height};    
    background-image: url(${Background});
`;

export const Text = styled.span`
    font-family: Montserrat;
    font-style: normal;
    
    &.TopWhite{
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        color: #FFFFFF;
    }
    &.TopBlue{
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;    
        color: #B0C2FF;
    }
    &.BlockTitle{
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.42);
    }
    &.BlockBig{
        font-weight: bold;
        font-size: 24.9339px;
        line-height: 30px;
        color: #FFFFFF;
    }
    &.BlockSmall{
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
    &.InviteBlue{
        font-weight: bold;
        font-size: 42.9096px;
        line-height: 52px;
        color: #B0C2FF;
    }
    &.ButtonText{
        font-weight: bold;
        font-size: 17.6525px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    }
    ${props => props.ButtonText && css`
        font-weight: bold;
        font-size: 17.6525px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    `};


`;

export const Block = styled.div`
  &.TitlePage{
      margin-top: 102px;
      width: 100%;
      height: 44px;
      margin-bottom: 85px;
  }   
  &.MiddleFist{
    width: 515px;
    height: 325px;
  }
  &.MiddleSecond{
    width: 435px;
    height: 325px;
  }
  &.MiddleThird{
    width: 350px;
    height: 325px;
  }
  &.MiddleTopBorder{
    border-top: 1px solid #91AAFF;
  }
  &.MiddleTopBorderInvisible{
    border-top: 1px solid rgb(0,0,0,0);
  }
  &.Bottom{
    margin-top: 88px;
  }
  &.InviteContent{
    margin-top: 30px;
  }
  &.InviteButton{
    margin-top: 45px;
  }
  &.Consultation{
    margin-top: 95px;
    width: 100%;
    height: 250px;
  }
`;
export const Button = styled.button`
  &.InviteButton{
    border: 0px;
    width: 289px;
    height: 66px;
    background: linear-gradient(105.59deg, #92AAFF 27.98%, #5877E4 72.43%), 
        linear-gradient(0deg, #001250, #001250), 
        linear-gradient(0deg, #001250, #001250),
        linear-gradient(0deg, #001250, #001250), 
        linear-gradient(0deg, #001250, #001250), #001250;
    border-radius: 6.52023px;  
  }
  &.InviteButton:active {
    animation: 0.5s ${keyframes`${pulse}`};
  }
`;