import styled, { keyframes } from 'styled-components';
//import { pulse } from 'react-animations';
import Background from './image/Background.svg'

export const FlexContainer = styled.div`
    display: flex; 
    
    &.center{
        justify-content: center;
        height: 682px;
    }
    &.stretch{
        align-items: stretch;
        height: 682px;
    }
    &.block{
        width: 100%;
        max-width: 1600px;
        alignment: center; 
        height: 682px;
        flex-direction: column;
    }
    &.directionRow{
        flex-direction: row;       
    }
    &.directionColumn{
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    &.stretchBlock{
        margin-top: 100px;
        justify-content: space-between;  
        width: 100%;
    }
`;
export const BackgroundStyle = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 682px;    
    background-image: url(${Background});
`;


export const Text = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    
    &.Big{    
        font-size: 42.9096px;
        line-height: 44px;
    }

    &.Small{
        font-size: 18px;
        line-height: 22px;
    }
    &.Small:first-child{        
        font-weight: bold;
        font-size: 24.93px;
        line-height: 30px;
    }
    &.Bold{

        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
    }
    &.ExtraBig{   
        text-align: center;
        font-weight: bold;
        font-size: 50.8605px;
        line-height: 62px;
    }
    &.BottomSecond{
        align-content: center;
        padding-left: 25px;
    }
    &.BottomThird{
        padding-left: 10px;
        padding-left: 45px;
    }
`;

export const Block = styled.div`
    &.TopFist{
        width: 346px;
        height: 155px;
    }
    &.TopSecond{
        width: 1165px;
        height: 128px;
        padding-top: 12px;
    }
    &.BottomFist{
        width: 184px;
        height: 110px;
        margin-top: 125px;
        margin-right: 180px;
    }
    &.BottomSecond{
        width: 254px;
        height: 130px;
        margin-top: 125px;
        margin-right: 160px;         
    }
    &.BottomThird{
      width: 330px;
      height: 152px;
      margin-top: 125px;
    }
  
  
`