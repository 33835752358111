import React, {PureComponent} from 'react'
import {
    FlexContainer,
    BackgroundStyle,
    BlockLeftContent,
    BlockRotated,
    BlockRightContent,
    DecorateBorder,
    ArrowAbout,
    TextGray,
    TextBig,
    TextWhite,
    TextButtonFourthBlock,
    ButtonGray,
    ButtonBlue,
    ButtonAbout,
    ButtonPlayRound,
    ButtonSocialNetwork,
} from './index.style.js'
import YouTube from 'react-youtube';
//import { Modal } from 'react-responsive-modal';


export default class MainScreen extends PureComponent {

    constructor(props) {
        super(props);
    }

    state = {
        allBalance: 100000,
        open: false,
    }
    componentDidMount() {
        setInterval(()=>
        {
            this.setState({allBalance: this.state.allBalance+Math.round(Number(Math.random()*1000))})
        },500)
    }
    onOpenModal = () => this.setState({open: true});
    onCloseModal = () => this.setState({open: false});


    getJSXAllBalanceDeposite = () => {
        let balance = this.state.allBalance.toString();
        let balanceChar = balance.split('');
        let arrbalance=[];
        let j=0;
        for (let i = balanceChar.length-1; i >= 0; i--)
        {
            if ((j)%3 == 0 && j !== 0)
            {
                arrbalance.push('.')
            }
            arrbalance.push(<TextWhite key={"balance"+j} className = "BigUnderlined">{balanceChar[i]}</TextWhite>)
            j++
        }
        arrbalance.reverse()
        return (arrbalance
            /*<span>
                <WhiteText className = "BigUnderlined">1</WhiteText>
                <WhiteText className = "BigUnderlined">0</WhiteText>
                <WhiteText className = "BigUnderlined">0</WhiteText>
                .
                <WhiteText className = "BigUnderlined">0</WhiteText>
                <WhiteText className = "BigUnderlined">0</WhiteText>
                <WhiteText className = "BigUnderlined">0</WhiteText>
            </span>*/
        )
    }


    render() {

        const opts = {
            height: '390',
            width: '640',
            playerVars: {
                autoplay: 1,
            }
        }

        let textDeposinte = this.getJSXAllBalanceDeposite()

        return (
            <FlexContainer className="center stretch">
                <BackgroundStyle>
                    <FlexContainer className="center">
                        <FlexContainer className="block">

                            <FlexContainer className="directionColumn">
                                <BlockLeftContent className="FistBlock">
                                    <TextGray className="Small">Программное обеспечение для автоматизированной торговли</TextGray>
                                </BlockLeftContent>
                                <BlockLeftContent className="SecondBlock">
                                    <TextBig className="Black">Избавьтесь от рутины <br/>и
                                    <TextBig className="Blue"> увеличивайте прибыль,</TextBig> доверяя нашим роботам</TextBig>
                                </BlockLeftContent>
                                <BlockLeftContent className="ThirdBlock">
                                    <TextGray className="Middle Bold">Получите единый пакет возможностей:</TextGray>
                                    <TextGray className="Middle"> мониторинг финансов и удаленный зароботок</TextGray>
                                </BlockLeftContent>
                                <BlockLeftContent className="FourthBlock">
                                    <ButtonBlue>
                                        <TextButtonFourthBlock className = "TextBlueButton">
                                            Приобрести
                                        </TextButtonFourthBlock>
                                    </ButtonBlue>
                                    <ButtonGray>
                                        <TextButtonFourthBlock className = "TextGrayButton">
                                            Стать клиентом
                                        </TextButtonFourthBlock>
                                    </ButtonGray>
                                </BlockLeftContent>
                                <ButtonAbout>
                                    <ArrowAbout/>
                                    <TextGray className="About">Узнайте о нас больше</TextGray>
                                </ButtonAbout>
                            </FlexContainer>
                            <FlexContainer>

                                <ButtonPlayRound onClick = {this.onOpenModal}/>


                            </FlexContainer>

                            <FlexContainer className = "directionColumn">
                                <BlockRightContent className = "FistBlock">
                                    <BlockRotated>
                                        <FlexContainer className = "directionColumn">
                                            <DecorateBorder/>
                                            <TextWhite className = "Small">Цифры на данном счетчике<br/> обновляются и растут ежедневно</TextWhite>
                                            <TextWhite className = "Big">{textDeposinte}<TextWhite className="Dollar">$</TextWhite></TextWhite>
                                            <TextWhite className = "Middle">Убедитесь в эффективности продукта,<br/> взглянув на общий баланс депозитов<br/> наших партнеров</TextWhite>
                                        </FlexContainer>
                                    </BlockRotated>
                                </BlockRightContent>
                                <BlockRightContent className = "SecondBlock">
                                    <ButtonSocialNetwork className = "Instagram"/>
                                    <ButtonSocialNetwork className = "Facebook"/>
                                    <ButtonSocialNetwork className = "Telegram"/>
                                </BlockRightContent>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </BackgroundStyle>
            </FlexContainer>
        )
    }
}