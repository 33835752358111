import React, {PureComponent} from 'react'
import Footer from  "./footer"
import AboutMDAY from  "./frame1AboutMDAY"
import WhyMDAY from  "./frame2WhyMDAY"
import Commentaries from "./frame3Commentaries"
import BuyExamples from  "./frame4BuyExamples"
import AccountExamples from  "./frame5AccountExamples"
import MainScreen from  "./mainScreen"
import Header from  "./header"


export default class HomePage extends PureComponent {

    constructor(props) {
        super(props);
    }
    state = {

    }

    render() {
        return (
            <div>
                {/*
                <Header/>
                */}
                <MainScreen/>
                <AboutMDAY/>
                <WhyMDAY/>
                {/*
                <BuyExamples/>
                <AccountExamples/>
                <Commentaries/>
                <Footer/>
                */}
            </div>
        )
    }
}