import React, {PureComponent} from 'react'
import {Block} from './index.style'
import {Text, FlexContainer} from "../../index.style";

export default class ListItem extends PureComponent {
    render() {
        return (
            <FlexContainer className = "directionColumn">
                <Block className = "Number">
                    <Text className = "BlockTitle">{this.props.Number}</Text>
                </Block>
                <Block className = "Title">
                    <Text className = "BlockBig">{this.props.Title}</Text>
                </Block>
                <Block className = "Content">
                    <Text className = "BlockSmall">{this.props.Text}</Text>
                </Block>
            </FlexContainer>
        )
    }
}