import React, {PureComponent} from 'react'
import {
    FlexContainer,
    BackgroundStyle,
    Text,
    Block,
} from './index.style.js'


export default class AboutMday extends PureComponent {

    constructor(props) {
        super(props);
    }

    state = {
    }

    render() {
        return (
            <FlexContainer className="center stretch">
                <BackgroundStyle>
                    <FlexContainer className="center">
                        <FlexContainer className="block">
                            <FlexContainer className = "stretchBlock">
                                <Block className = "TopFist">
                                    <Text className = "Big">Теперь немного о нас</Text>
                                </Block>
                                <Block className = "TopSecond">
                                    <div>
                                        <Text className = "Small">MDAY</Text>
                                        <Text className = "Small"> - это</Text>
                                        <Text className = "Bold Small"> команда профессиональных программистов и трейдеров,</Text>
                                        <Text className = "Small"> математиков и исследователей из разных уголков <br/>страны.</Text>
                                        <Text className = "Bold Small"> Мы автоматизировали работу трейдера,</Text>
                                        <Text className = "Small"> преобразовав его торговую стратегию в программный код.
                                            Помните как Стив <br/> Джобс, доставая iPod с кармана, сказал:
                                            «1000 песен в твоём кармане»? Так и мы сейчас
                                            достаём смартфон с кармана и говорим <br/> “Собственный карманный трейдер в твоём
                                            распоряжении.
                                        </Text>
                                        <Text className = "Bold Small"> Наш революционный сотрудник MAYDAY будет работать за вас! <br/>
                                        </Text>
                                        <Text className = "Small"> Подобно
                                            мультиварке с отложенным таймером, чайнику с дистанционным управлением
                                            или системе “умный дом”.
                                        </Text>
                                    </div>
                                </Block>
                            </FlexContainer>
                            <FlexContainer className = "directionRow">
                                <Block className = "BottomFist">
                                    <FlexContainer className = "directionColumn">
                                        <Text className = "ExtraBig"> > 1000 </Text>
                                        <Text className = "Small">довольных клиентов</Text>
                                    </FlexContainer>
                                </Block>
                                <Block className = "BottomSecond">
                                    <FlexContainer className = "directionColumn">
                                        <Text className = "ExtraBig"> 3 </Text>
                                        <Text className = "Small BottomSecond">года лидерства в сфере<br/> торговли и инвестиций</Text>
                                    </FlexContainer>
                                </Block>
                                <Block className = "BottomThird">
                                    <FlexContainer className = "directionColumn">
                                        <Text className = "ExtraBig"> 24/7 </Text>
                                        <Text className = "Small BottomThird">мы поддерживаем наших <br/>клиентов и помогаем освоить <br/>новый источник зароботка</Text>
                                    </FlexContainer>
                                </Block>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </BackgroundStyle>
            </FlexContainer>
        )
    }
}