//import React from 'react';

import {React, PureComponent}  from 'react';
import { Switch, Route } from 'react-router-dom'
import HomePage from "./components/Page/homePage";
import ErrorCode404 from "./containers/SimplePage/404";
import ErrorCode500 from "./containers/SimplePage/500";
//import { Route, Redirect } from 'react-router-dom';
//import { ConnectedRouter } from 'react-router-redux';
//import { connect } from 'react-redux';

export default class Router extends PureComponent {

    constructor(props) {
        super(props);
    }

    state = {}

    render() {
        return (

            <Switch>
                <Route exact path='/' component={HomePage}/>
                <Route exact path='/home' component={HomePage}/>
                <Route exact path='/404' component={ErrorCode404}/>
                <Route exact path='/500' component={ErrorCode500}/>


                {/* Оба /roster и /roster/:number начинаются с /roster
        <Route path='/roster' component={Roster}/>
        <Route path='/schedule' component={Schedule}/>
        <Route
            exact
            path={'/'}
            component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        */}
            </Switch>
        )
    }
}





