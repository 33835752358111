import styled from 'styled-components';
import Dot from './image/Dot.svg'

export const Block = styled.div`
  position: relative;  
  &.Number:before{
    position: absolute;
    content: url(${Dot});  
    width: 11px;
    height: 11px;
    top: -8px;
  }
  &.Number{
    padding-top: 35px;
  }
  &.Title{
    margin-top: 8px;
    height: 74px;
  }
  &.Content{
    width: 440px;
  }
`;