import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

import Telegram from './image/Telegram.svg'
import Instagram from './image/Instagram.svg'
import Facebook from './image/Facebook.svg'
import Background from './image/Background.svg'
import Arrow from './image/ArrowAbout.svg'
import PlayButton from './image/PlayRoundButton.svg'

export const ButtonGray = styled.button`
    width: 220.03px;
    height: 100%;    
    border: 3.01413px solid #808080;
    box-sizing: border-box;
    
    &:hover {
        background: white;//#e6e6e6;
        border: 3.01413px solid #4d4d4d;
    }

    &:active {
        animation: 0.5s ${keyframes`${pulse}`};
    }
    
`;
export const ButtonBlue = styled.button`
    width: 182.35px;
    height: 100%;
    background: #0047FF;
    border: 0px;  
    
    &:hover {
       background: #002b99;
    }

    &:active {
        animation: 0.5s ${keyframes`${pulse}`};
    }
`;
export const ButtonSocialNetwork = styled.button`
    margin-right: 10px;
    border: 0px; 
    background-color: rgba(0, 0, 0, 0);

    &.Telegram{
        width: 31px;
        height: 26px;
        background-image: url(${Telegram}) ;    
    }
    &.Instagram{
        width: 28px;
       height: 28px; 
       background-image: url(${Instagram}) ;    
    }
    &.Facebook{
       width: 28px;
       height: 28px;
       background-image: url(${Facebook}) ;    
    }

    &:hover {
    
    }

    &:active {
        animation: 0.5s ${keyframes`${pulse}`};
    }
`;
export const ButtonAbout = styled.button`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 112px;
    width: 200px;
    height: 19px; 
    border: 0px;
    padding: 0px;
`;
export const ButtonPlayRound = styled.button`
    margin-top: 517px;
    margin-left: 380px;
    width: 140px;
    height: 140px;
    border: 0px; 
    border-radius: 50%;
    background-image: url(${PlayButton}) ;    
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
    
    &:hover {
        opacity: 1;
    }

    &:active {
        animation: 0.5s ${keyframes`${pulse}`};
    }
`;

export const BackgroundStyle = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 885.33px;    
    background: #EEF7FF;
    opacity: 0.8;
    background-image: url(${Background});
    
`;
export const FlexContainer = styled.div`
    display: flex; 
    
    &.center{
        justify-content: center;
        height: 885.33px;
    }
    &.stretch{
        align-items: stretch;
        height: 885.33px;
    }
    &.block{
        width: 100%;
        max-width: 1600px;
        alignment: center; 
        height: 885.33px;
    }
    &.directionColumn{
        flex-direction: column;       
    }
`;
export const BlockLeftContent = styled.div`
    &.FistBlock{
        margin-top: 218.52px;
        width: 221.54px;
        height: 30.14px;
        border-left: 3px solid #34495E;
    }
    &.SecondBlock{
        margin-top: 30px;
        width: 670px;
        height: 180px; 
    }
    &.ThirdBlock{
        width: 480px;
        height: 50px; 
    }
    &.FourthBlock{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 46px;
        width: 440px;
        height: 67px; 
    }

`;
export const BlockRightContent = styled.div`
    &.FistBlock{
        margin-top: 431px;
        height: 300px;
        width: 400px;
        padding-left: 95px;
    }
    &.SecondBlock{
      padding-left: 310px;
      padding-top: 50px;
    } 
`
export const BlockRotated = styled.div`
    transform: rotate(-17.86deg);
`;
export const ArrowAbout = styled.div`
    width: 12px;
    height: 20px;
    background-image: url(${Arrow})  
`;
export const DecorateBorder = styled.div`
  width: 30px;
  height: 3px;
  border-top: 3px solid #34495E;
  margin-bottom: 10px;
`;

export const TextWhite = styled.span`
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    //margin-left: 50px;    
    
    &.Small{       
       /* margin-top: 475px;*/
        font-family: Inter;
        font-size: 12.0565px;
        line-height: 15px;  
    }
    &.Middle{
        
        font-family: Inter;
        font-size: 16.3215px;
        line-height: 20px;
        margin-top: 50px;
    }
    &.Big{
        margin-top: 20px;
        //height: 62px;
        font-family: Metrophobic;
        font-size: 61.3858px;
        line-height: 76px;
       /* letter-spacing: 0.1em;*/
    }
    &.BigUnderlined{
      //margin-left: 0px;
      border-bottom: 3px solid #FFFFFF;
      width: 15px;
      margin: 3px;
    }
    &.BigUnderlined:first-child 
    {
      margin-left: 0px;
    }
    
    
    &.Dollar{
        margin-left: 0px;    
        font-family: Metrophobic;
        font-size: 27.1272px;
        line-height: 33px;
        letter-spacing: 0.1em;
    }
`;
export const TextBig = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 48.4009px;
    line-height: 48px;        
    letter-spacing: -0.03em; 

    &.Black{     
        color: #34495E;
    }
    
    &.Blue{
        color: #739AFF;        
    } 
`;
export const TextGray = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: #6C6C6C; 
    
    &.Small{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 19px;
        font-size: 12.0565px;
        line-height: 98.79%;     
    }
    &.Middle{
        font-size: 16.3261px;
        line-height: 20px;   
    }    
    &.Bold{
        font-weight: bold;
        letter-spacing: 0.02em;
    } 
    &.AboutText{
        font-size: 16.3215px;
        line-height: 98.79%;
    }
`;
export const TextButtonFourthBlock = styled.span`
    &.TextBlueButton{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 17.7999px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        
        color: #FFFFFF;
    }
    &.TextGrayButton{    
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 17.7999px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        
        color: #808080;
    }
`;

